import { NgModule }                  from '@angular/core';
import { CommonModule }              from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule }                 from '@angular/common/http';
/* Import Interceptors Deps */
import { LoaderService }             from './services/loader.service';
import { LoaderInterceptor }         from './interceptors/loader.interceptor';


@NgModule({

  imports: [
    CommonModule,
    HttpClientModule
  ],

  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
  ],

  declarations: []

})

export class CoreModule { }
