/* Angular Imports */
import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule } from '@angular/router';
/* Feature Imports */
import { InterfaceComponent } from './interface.component';
import { PageTitleResolver } from './page-title.resolver';
import { WelcomeComponent } from '../shared/components/welcome/welcome.component';


/**
 * Define Interface Routing Instructions
 */
const interfaceRoutes: Routes = [

  {
    path: '',
    component: InterfaceComponent,
    children: [
      {
        path: '',
        component: WelcomeComponent
      },
      {
        path: 'preferences',
        loadChildren: () => import('./../routes/subscription/subscription.module').then(m => m.SubscriptionModule),
        data: {
          token: 'app.hero.subscription'
        },
        resolve: {
          title: PageTitleResolver
        }
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./../routes/contact-form/contact-form.module').then(m => m.ContactFormModule),
        data: {
          token: 'app.hero.contact-us'
        },
        resolve: {
          title: PageTitleResolver
        }
      }
    ]
  }

];


@NgModule({

  imports: [
    RouterModule.forChild(interfaceRoutes)
  ],

  exports: [
    RouterModule
  ]

})


export class InterfaceRoutingModule { }

/* Export Feature Components */
export const routedComponents = [
  InterfaceComponent
];
