/* Angular Imports */
import {
  Component,
  OnInit
} from '@angular/core';
/* Translation Imports */
import {
  FormBuilder,
  FormGroup
} from '@angular/forms';
import { OuxDropdownOptions, OuxConfigService } from '@cisco/oux-common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-header-language',
  templateUrl: './header-language.component.html',
  styleUrls: ['./header-language.component.scss'],
  host: {
    'class': 'header-language'
  }
})


export class HeaderLanguageComponent implements OnInit {

  private languages: Array<{ code: string, name: string }> = [];

  public setLanguageOptions: OuxDropdownOptions;

  private currentLanguage: { code: string, name: string };

  public menuVisible = false;

  public languageInput: FormGroup;

  constructor(
    private translate: TranslateService,
    private configService: OuxConfigService,
    private fb: FormBuilder) {

    this.currentLanguage = this.configService.getAppConfigValue('defaultLanguage') as { code: string, name: string };

    this.languages = this.configService.getAppConfigValue('supportedLanguages') as Array<{ code: string, name: string }> || [];

    this.setLanguageOptions = new OuxDropdownOptions({
      placeholder: 'Eng/中文',
      list: this.languages,
      keyProperty: 'code',
      displayProperty: 'name'
    });

    this.languageInput = this.fb.group({
      setLanguage: [this.currentLanguage]
    });

    this.watchLanguageChange();

  }

  ngOnInit(): void {

    this.translate.onLangChange
      .subscribe((event: LangChangeEvent) => {

        let language =
          this.languages.find(lg => lg.code === event.lang);

        if (!language) {
          language = this.currentLanguage;
        }

        this.languageInput.setValue({
          setLanguage: language
        });

      });

  }

  private watchLanguageChange(): void {

    this.languageInput.get('setLanguage').valueChanges.subscribe(
      val => {
        this.currentLanguage = val;
        this.translate.use(this.currentLanguage.code);
      }
    );

  }

  public showMenu() {
    this.menuVisible = true;
  }
  public hideMenu() {
    this.menuVisible = false;
  }

}
