/*
 * Angular Imports
 */
import { NgModule }                   from '@angular/core';
import { CommonModule }               from '@angular/common';
import { RouterModule }               from '@angular/router';
/*
 * Package Imports
 */
import {
  OuxGlobalNavModule,
  OuxSideNavModule }                  from '@cisco/oux-common';
/* 
 * Per Dependancies
 */
import { SharedModule }               from '../shared/shared.module';
/* 
 * Feature Imports
 */
import { HeaderComponent }            from './header/header.component';
import { HeaderLogoComponent }        from './header/header-logo/header-logo.component';
import { HeaderLanguageComponent }    from './header/header-language/header-language.component';

import { ToolbarComponent }           from './toolbar/toolbar.component';
import { ToolbarModalComponent }      from './toolbar/toolbar-modal/toolbar-modal.component';
import { ToolbarUserComponent }       from './toolbar/toolbar-user/toolbar-user.component';
import { ToolbarSettingsComponent }   from './toolbar/toolbar-settings/toolbar-settings.component';

import { BreadcrumbsComponent }       from './breadcrumbs/breadcrumbs.component';
import { CrumbContainerDirective }    from './breadcrumbs/directives/crumb-container.directive';

import { AsideComponent }             from './aside/aside.component';
import { BodyComponent }              from './body/body.component';
import { HeroComponent }              from './hero/hero.component';
import { ShelfComponent }             from './shelf/shelf.component';
import { FooterComponent }            from './footer/footer.component';
import { RootLogoutComponent }        from '../guards/logout/logout.component';


@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    OuxGlobalNavModule,
    OuxSideNavModule,
    SharedModule
  ],

  declarations: [
    HeaderComponent,
    HeaderLogoComponent,
    HeaderLanguageComponent,
    ToolbarComponent,
    ToolbarModalComponent,
    ToolbarUserComponent,
    ToolbarSettingsComponent,
    BreadcrumbsComponent,
    CrumbContainerDirective,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent
  ],

  providers: [

  ],

  entryComponents: [
    ToolbarModalComponent,
    ToolbarSettingsComponent
  ],

  exports: [
    RouterModule,
    HeaderComponent,
    HeaderLogoComponent,
    BreadcrumbsComponent,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent
  ]

})

export class LayoutModule { }
