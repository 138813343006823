/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef } from '@angular/core';
/* RxJS Imports */
import { Subject, from, of } from 'rxjs';
/* Package Imports */
import {
  OuxRouterService,
  OuxLayoutService,
  OuxLoggerService } from '@cisco/oux-common';
/* Feature Imports */
import { HeroWhiteList } from './whitelist';
import { InstanceService } from '../../shared/services/instance.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { takeUntil, switchMap, tap } from 'rxjs/operators';


/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  host: {
    class: 'app-hero'
  }
})
export class HeroComponent implements OnInit, OnDestroy {

  private readonly destroy$ = new Subject<boolean>();

  private token: string;

  /**
   * Manages router endstate titles
   */
  public title: string = null;

  public showHero: boolean;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef: ViewContainerRef,
              private routerSvc: OuxRouterService,
              private layoutSvc: OuxLayoutService,
              private loggerSvc: OuxLoggerService,
              private translateService: TranslateService,
              private instanceSvc: InstanceService) {

    this.setHeroTitle();

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {

    this.instanceSvc.instanceMountedAt('HeroComponent', 'app-hero');
    this.setHeroState();

    this.translateService.onLangChange
      .pipe(
        switchMap((event: LangChangeEvent) => {

          if (!this.token) {
            return of('');
          }

          const keys =
            this.token.split('.');

          const value =
            keys.reduce((acc, key) => {
              return acc[key];
            }, event.translations);

          return of(value);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(title => {
        this.title = title;
      });

  }

  ngOnDestroy(): void {

    this.destroy$.next(true);
    this.destroy$.unsubscribe();

  }

  ////////////////////////////////////////////////
  // Feature Methods
  ////////////////////////////////////////////////

  private setHeroTitle(): void {

    this.routerSvc.getActiveRouteData()
        .pipe(
          tap(data => {
            this.token = data.token;
          }),
          switchMap(data => {

            if (!data.token) {
              return of('');
            }

            return this.translateService.get(data.token);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(
          (title: any) => {
            this.title = title;
        });

  }

  /**
   * Hides our Hero Container located inside the Interface Template
   */
  private setHeroState(): void {

    this.layoutSvc.getHeroState()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (state: boolean) => {
            this.showHero = state;
        });

  }


}
