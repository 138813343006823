<!-- mq container -->
<div ouxDeviceDetector [ngClass]="{ 'interface' : true, '--fullwidth' : !showSideNav, '--has-footer' : showFooter }">

  <!-- primary header container -->
  <app-header></app-header>

  <!-- primary breadcrumbs container -->
  <app-breadcrumbs></app-breadcrumbs>

  <!-- primary side navigation container -->
  <app-aside></app-aside>

  <!-- primary content container -->
  <app-body>

    <!-- primary hero container -->
    <app-hero></app-hero>
    
    <!-- generic container -->
    <!-- <app-shelf></app-shelf> -->

    <!-- primary content outlet -->
    <router-outlet></router-outlet>

  </app-body>

  <!-- primary footer -->
  <app-footer></app-footer>

  <!-- global drawer container -->
  <oux-drawer>
    <oux-drawer-header></oux-drawer-header>
  </oux-drawer>

  <!-- global toast container -->
  <oux-toast></oux-toast>

  <!-- global modal container -->
  <oux-modal></oux-modal>

  <!-- global status loader -->
  <oux-loader [show]="loading"></oux-loader>

</div>
