declare var LE: any;

/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy }                  from '@angular/core';
import { Subscription }        from 'rxjs';
/* Package Imports */
import {
  OuxRouterService,
  OuxLoggerService, 
  OuxConfigService}           from '@cisco/oux-common';
import { InstanceService }     from './shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shell',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  host: {
    'class': 'app-shell'
  }
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private defaultLanguage = {
    code: 'en-us',
    name: 'English'
  };

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private routerSvc: OuxRouterService,
              private loggerSvc: OuxLoggerService,
              private translate: TranslateService,
              private configService: OuxConfigService,
              private instanceSvc: InstanceService) {

    // Initializes the route history feature
    this.routerSvc.loadRouteHistory();

    // Initializes the dynamic title builder feature
    this.routerSvc.initDynamicTitleBuilder();

    const logEntriesConfig = this.configService.getAppConfigValue('logentries');

    const enableLogging = logEntriesConfig.enableAlternate;

    if (enableLogging) {
      const logToken = logEntriesConfig.token;
      LE.init({token: logToken, trace: true, catchall: true, page_info: 'per-entry', print: true});
    }

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {

    this.instanceSvc.instanceMountedAt('AppComponent', 'app-shell');

    /**
     * Return the lastest route tree
     */
    this.subscriptions.push(
      this.routerSvc.getRouteHistory()
        .subscribe(
          (history : string) => {
          this.loggerSvc.logDebug('Route History:', history);
      })
    );

    /**
     * Returns a boolean
     * Title changed due to route changes (true/false)
     */
    this.subscriptions.push(
      this.routerSvc.routeTitleChanged()
        .subscribe(
          (value: boolean) => {
          this.loggerSvc.logDebug('Route Changed:', value);
        })
    );

    const languages = this.configService.getAppConfigValue('supportedLanguages') as Array<{ code: string, name: string }> || [];

    this.translate.setDefaultLang(this.defaultLanguage.code);

    const browserLang = navigator.language.toLowerCase();

    let supportedLanguageIndex = languages.findIndex(language => language.code === browserLang);

    if (supportedLanguageIndex === -1) {
      supportedLanguageIndex = languages.findIndex(language => language.code.substring(0, 2) === browserLang.substring(0, 2));
    }

    if (supportedLanguageIndex !== -1) {
      const languageFound = languages[supportedLanguageIndex];
      this.defaultLanguage.code = languageFound.code;
      this.defaultLanguage.name = languageFound.name;
    }

    this.translate.use(this.defaultLanguage.code);

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

}
