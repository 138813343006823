/* Angular Imports */
import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
/* RxJS Imports */
import {
  Subject,
  Subscription
} from 'rxjs';
/* Package Imports */
import {
  OuxLayoutService,
  OuxLoggerService
} from '@cisco/oux-common';
import { LoaderService } from '../core/services/loader.service';
import { InterfaceBase } from './interface.base';
import { InstanceService } from '../shared/services';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ChildActivationEnd } from '@angular/router';


@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss'],
  host: {
    'class': 'app-interface'
  },
})
export class InterfaceComponent extends InterfaceBase implements OnInit, AfterViewInit, OnDestroy {

  public loading = true;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(
    private router: Router,
    layoutSvc: OuxLayoutService,
    loggerSvc: OuxLoggerService,
    instanceSvc: InstanceService,
    loaderSvc: LoaderService) {
    super(
      layoutSvc,
      loggerSvc,
      instanceSvc,
      loaderSvc
    );

    const self = this;

    const routerEvents$ =
      self.router.events.subscribe(event => {

        switch (true) {
          case event instanceof NavigationStart: {
            self.loading = true;
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {
            setTimeout(() => self.loading = false, 1000);
            break;
          }
          default: {
            break;
          }
        }

      });

    this.subscriptions.push(routerEvents$);

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {
    this.instanceSvc.instanceMountedAt('InterfaceComponent', 'app-interface');
    this.setLayoutStates();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Convenience function. Responsible for envoking all state initialization code
   */
  private setLayoutStates(): void {
    this.checkSideNavState();
    this.checkFooterState();
  }

  /**
   * Adds --full-width class to interface template if showSideNav is false
   */
  private checkSideNavState(): void {
    this.subscriptions.push(
      this.layoutSvc.getSideNavState()
        .subscribe((state: boolean) => {
          this.showSideNav = state;
        })
    );
  }

  /**
   * Adds --has-footer class to interface template if showFooter is true
   */
  private checkFooterState(): void {
    this.subscriptions.push(
      this.layoutSvc.getFooterState()
        .subscribe((state: boolean) => {
          this.showFooter = state;
        })
    );
  }

}
