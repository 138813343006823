/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }              from '@angular/core';
/* RxJS Imports */
import { Subscription }           from 'rxjs';
/* Package Imports */
import {
  OuxSideNavOptions,
  OuxLayoutService }              from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }        from '../../shared/services/instance.service';


@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
  host: {
    'class': 'app-aside'
  }
})
export class AsideComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private aside : HTMLElement | any;
  /**
   * Is side nav present
   */
  public showSideNav : boolean = null;
  /**
   * Define side nav options
   */
  public setOptions : OuxSideNavOptions = new OuxSideNavOptions ({
    endPoint: '/api/sidenav.json',
    entryRoute: '/dashboard',
    iconRef: 'icon-cisco',
    navHeadline: 'App Name',
    appVersion: null
    // replaceHeader: false,
    // replaceFooter: false,
    // compressItems: false
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private layoutSvc : OuxLayoutService,
              private instanceSvc : InstanceService) {

    this.aside = this.vcRef.element.nativeElement;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('AsideComponent', 'app-aside');
    this.getLayoutState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Adds --full-width class to aside template if showSideNav is false
   */
  private getLayoutState() {
    this.subscriptions.push(
      this.layoutSvc.getSideNavState()
        .subscribe( (state : boolean) => {
          this.showSideNav = state;
          this.handleLayoutChanges();
      })
    );
  }

  /**
   * Set class state based on inbound conditions
   */
  private handleLayoutChanges() {
    if (this.showSideNav == false) {
      this.aside.classList.add('--fullwidth');
    }
    else {
      this.aside.classList.remove('--fullwidth');
    }
  }

}
