/* Angular Imports */
import {
  Component,
  OnInit } from '@angular/core';


@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  host: {
    'class': 'app-header-logo'
  }
})
export class HeaderLogoComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}


}
