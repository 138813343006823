/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef } from '@angular/core';
/* RxJS Imports */
import { Subscription, of } from 'rxjs';
/* Package Imports */
import {
  OuxLayoutService,
  OuxGlobalNavOptions,
  OuxRouterService,
  OuxLoggerService,
  OuxConfigService,
  OuxGlobalNavItems} from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService } from '../../shared/services/instance.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';


/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    'class': 'app-header slideInDown animated'
  }
})
export class HeaderComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions: Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private header: HTMLElement | any;
  /**
   * Is side nav present
   */
  public showSideNav: boolean = null;
  /**
   * Manage Header Component Instance
   */
  public headerInstance: string = null;

  /**
   * Nav Items
   */
  private navItems: [any];

  public setNavOptions: OuxGlobalNavOptions = new OuxGlobalNavOptions({
    type: 'underlined',
    navItems: [],
    show: true
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef: ViewContainerRef,
              private layoutSvc: OuxLayoutService,
              private routerSvc: OuxRouterService,
              private loggerSvc: OuxLoggerService,
              private configSvc: OuxConfigService,
              private translateService: TranslateService,
              private instanceSvc: InstanceService) {

    this.setHeaderInstance();
    this.header = this.vcRef.element.nativeElement;

    this.navItems = this.configSvc.getAppConfigValue('navItems') as [any];
    this.loggerSvc.logDebug('Nav Items', this.navItems);

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {
    this.instanceSvc.instanceMountedAt('HeaderComponent', 'app-header');
    this.getLayoutState();

    this.translateService.onLangChange
      .pipe(
        switchMap((event: LangChangeEvent) => {
          const mappedItems = this.navItems.map(item => {

            const keys =
              item.caption.split('.');

            const caption =
              keys.reduce((acc, key) => {
                return acc[key];
              }, event.translations);

            return new OuxGlobalNavItems({ ...item, caption });

          });

          return of(mappedItems);
        })
      )
      .subscribe((navItems: OuxGlobalNavItems[]) => {

        this.setNavOptions = new OuxGlobalNavOptions({
          ...this.setNavOptions,
          navItems
        });

      });

    const items = this.navItems.map(item => {
      const caption = this.translateService.instant(item.caption);
      return new OuxGlobalNavItems({ ...item, caption });
    });

    this.setNavOptions = new OuxGlobalNavOptions({
      ...this.setNavOptions,
      navItems: items
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( (sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Dynamically Get/Set new inboud route title
   */
  private setHeaderInstance(): void {

    this.subscriptions.push(
      this.routerSvc.getActiveRouteData()
        .subscribe(
          (data: any) => {
            this.loggerSvc.logDebug('Draw Header Instance:', data.title);
            this.headerInstance = data.title;
        })
    );

  }

  /**
   * Adds --full-width class to header template if showSideNav is false
   */
  private getLayoutState(): void {

    this.subscriptions.push(
      this.layoutSvc.getSideNavState()
        .subscribe(
          (state: boolean) => {
            this.showSideNav = state;
            this.handleLayoutChanges();
        })
    );

  }

  /**
   * Set class state based on inbound conditions
   */
  private handleLayoutChanges(): void {

    if (this.showSideNav === false) {
      this.header.classList.add('--fullwidth');
    } else {
      this.header.classList.remove('--fullwidth');
    }
  }

}
