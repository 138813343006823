import { PreloadingStrategy, Route }        from '@angular/router';
import { Observable, of }                   from 'rxjs';

export class PreloadSelectedModulesList implements PreloadingStrategy {

  /**
   * The preload method takes two parameters: a route and the function that actually does the preloading. 
   * In it we check if the preload property is set to true. And if it is, we call the load function.
   */
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data['preload'] ? load() : of(null);
  }

}
