/* Angular Imports */
import { NgModule }                       from '@angular/core';
import { CommonModule }                   from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule }                   from '@angular/forms';
import { TranslateModule }                from '@ngx-translate/core';
/* Package Imports */
import {
  OuxAlertModule,
  OuxButtonModule,
  OuxCheckboxModule,
  OuxContainerModule,
  OuxDrawerModule,
  OuxDropdownModule,
  OuxExampleViewerModule,
  OuxFormPartialsModule,
  OuxGaugeModule,
  OuxLoaderModule,
  OuxModalModule,
  OuxModalLayoutModule,
  OuxPaginationModule,
  OuxSwitchModule,
  OuxSyntaxHighlighterModule,
  OuxTabsModule,
  OuxTableModule,
  OuxTableLayoutModule,
  OuxTagModule,
  OuxToastModule,
  OuxTooltipModule,
  OuxTooltipComponent,
  OuxUtilitiesModule }                     from '@cisco/oux-common';  

/* Global Shared Service Imports */
import { InstanceService }                 from './services';
import { TranslateUtilsService }           from './services';

/* Filters */
import { DateFormatPipe }                  from './pipes/date-format.pipe';
import { DateTimeFormatPipe }              from './pipes/date-time-format.pipe';
import { DateLocalFormatPipe }             from './pipes/date-local-format.pipe';
import { StatusFilterPipe }                from './pipes/status-filter.pipe';
import { TruncateStringPipe }              from './pipes/truncate-string.pipe';
import { TrimStringPipe }                  from './pipes/trim-string.pipe';


// imports: imports the module's exports. which are usually
// declarables(components / directives / pipes) and providers.
// in our case the FilterTextModule has a provider.
//
// exports: exports modules AND declarables (components/directives/pipes) that other modules may want to use
// SharedModule does not use CommonModule, but does use FormsModule.
// Even so, we import/export both of these because most other modules will import SharedModule and will need them.
@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // UI Common Imports
    OuxAlertModule,
    OuxButtonModule,
    OuxCheckboxModule,
    OuxContainerModule,
    OuxDrawerModule,
    OuxDropdownModule,
    OuxExampleViewerModule,
    OuxFormPartialsModule,
    OuxGaugeModule,
    OuxLoaderModule,
    OuxModalModule,
    OuxModalLayoutModule,
    OuxPaginationModule,
    OuxSwitchModule,
    OuxSyntaxHighlighterModule,
    OuxTabsModule,
    OuxTableModule,
    OuxTableLayoutModule,
    OuxTagModule,
    OuxToastModule,
    OuxTooltipModule,
    OuxUtilitiesModule
  ],

  declarations: [
    DateFormatPipe,
    DateTimeFormatPipe,
    DateLocalFormatPipe,
    StatusFilterPipe,
    TruncateStringPipe,
    TrimStringPipe
  ],

  providers: [
    InstanceService,
    TranslateUtilsService
  ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,

    /* Begin UI Common Module Imports */
    OuxAlertModule,
    OuxButtonModule,
    OuxCheckboxModule,
    OuxContainerModule,
    OuxDrawerModule,
    OuxDropdownModule,
    OuxExampleViewerModule,
    OuxFormPartialsModule,
    OuxGaugeModule,
    OuxLoaderModule,
    OuxModalModule,
    OuxModalLayoutModule,
    OuxPaginationModule,
    OuxSwitchModule,
    OuxSyntaxHighlighterModule,
    OuxTabsModule,
    OuxTableModule,
    OuxTableLayoutModule,
    OuxTagModule,
    OuxToastModule,
    OuxTooltipModule,
    OuxUtilitiesModule,
    /* Begin Feature Pipes */
    DateFormatPipe,
    DateTimeFormatPipe,
    DateLocalFormatPipe,
    StatusFilterPipe,
    TruncateStringPipe,
    TrimStringPipe
  ],

  entryComponents: [
    OuxTooltipComponent
  ]

})

export class SharedModule { }
