/* ES6/TypeScript Imports */
import { NgModule }                               from '@angular/core';
import { BrowserModule }                          from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientModule }                              from '@angular/common/http';

/* NGX-Translate Imports */
import {
  TranslateModule,
  TranslateLoader }                               from '@ngx-translate/core';
import { MultiTranslateHttpLoader }               from './shared/decorators/multi-translation-http-loader';

/* Root Module Dependancies */
import { AppRoutingModule }                       from './app-routing.module';
import { AppComponent }                           from './app.component';
import { NotAuthorizedComponent }                 from './routes/notauthorized/notauthorized.component';
import { NotFoundComponent }                      from './routes/notfound/notfound.component';

/* Global Modules */
import {
  OuxConfigModule,
  OuxModalModule,
  OuxNotAuthorizedPartialModule,
  OuxNotFoundPartialModule }                      from '@cisco/oux-common';

/* Feature Modules */
import { CoreModule }                             from './core/core.module';
import { GuardsModule }                           from './guards/guards.module';
import { InterfaceModule }                        from './interface/interface.module';
import { SharedModule }                           from './shared/shared.module';


/* AOT requires an exported function for factories */
export function HttpLoaderFactory(http : HttpClient) {
  return new MultiTranslateHttpLoader( http, [
      {
        prefix: './assets/i18n/',
        suffix: '.json'
      }
  ]);
}


@NgModule({

  /**
   * Import Angular modules we depend on
   */
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,

    // Translation Deps
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    OuxConfigModule,
    OuxModalModule,
    OuxNotAuthorizedPartialModule,
    OuxNotFoundPartialModule,

    CoreModule,
    GuardsModule,
    InterfaceModule,
    SharedModule
  ],

  /**
   * Declare components, directives and pipes (i.e., filters)
   */
  declarations: [
    AppComponent,
    NotAuthorizedComponent,
    NotFoundComponent
  ],

  /** 
   * Provide services to app root injector
   */
  providers: [

  ],

  /*
   * Similar to the bootstrap process
   * If your app happens to bootstrap or dynamically load a component by type in some other manner, you'll want to add it here.
   */
  entryComponents: [

  ],

  /*
   * Bootstrap root component
   * This tells the compiler that this is an entry component and it should generate code to bootstrap the application with it.
   */
  bootstrap: [
    AppComponent
  ]

})

export class AppModule { }
