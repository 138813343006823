/* Angular Imports */
import { Injectable }               from '@angular/core';
/* RxJS Imports */
import { Subject }                  from 'rxjs';


@Injectable()
export class LoaderService {

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  public isLoading : Subject<boolean>;

  constructor() {

    this.isLoading = new Subject<boolean>();

  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Loading Interceptor will change the subject value to true, when a request starts
   */
  public show() : void {
    this.isLoading.next(true);
  }

  /**
   * Loading Interceptor will “hide” it when the request is “finalized”
   */
  public hide() : void {
    this.isLoading.next(false);
  }

}
