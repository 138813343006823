/* Angular Imports */
import { Injectable }               from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest }                     from '@angular/common/http';
/* RxJS Imports */
import { Observable }               from 'rxjs';
import { finalize }                 from 'rxjs/operators';
/* Fearure Imports */
import { LoaderService }            from '../services/loader.service';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private loaderSvc : LoaderService) {}

  intercept(request : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {

    this.loaderSvc.show();

    return next.handle(request)
      .pipe(
        // Finalize:
        // Calls our callback function on both success and error responses. 
        // This way, we can be sure that our application will not end up with a non-stop loader status.
        finalize( () => this.loaderSvc.hide() )
      );
  }

}
