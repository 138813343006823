/* Angular Imports */
import { Component, OnInit }        from '@angular/core';
/* Package Imports */
import { OuxConfigService }         from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }          from '../../shared/services/instance.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  host: {
    'class': 'app-login'
  }
})
export class RootLoginComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public header : string = 'Add desired title';
  public message : string = 'Add desired messaging.';
  public redirectAfter : string = '/dashboard';

  /**
   * The following properties are managed in 'app/config/app.{environment}.json'
   */
  public clientId : string;
  public ssoUri : string;
  public redirectUri : string;
  public gatewayUri : string;
  public enableDeepLinking : boolean;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private configSvc : OuxConfigService,
              private instanceSvc : InstanceService) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {

    this.instanceSvc.instanceMountedAt('LoginComponent', 'app-login');
    /**
     * Init login configuration
     */
    this.configureLogin();

  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private configureLogin() : void {
    this.clientId = this.configSvc.getAppConfigValue('clientId');
    this.ssoUri = this.configSvc.getAppConfigValue('ssoUri');
    this.redirectUri = this.configSvc.getAppConfigValue('appUri');
    this.gatewayUri = this.configSvc.getAppConfigValue('gatewayUri');
    this.enableDeepLinking = this.configSvc.getAppConfigValue('enableDeepLinking');
  }

}
